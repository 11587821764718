const queryKeys = {
  AUTH_CLIENT: 'auth-client',
  CODE_SAMPLE_LIST: 'code-sample-list',
  CODE_SAMPLE_DETAIL: 'code-sample-detail',
  CODE_SAMPLE_FILTERS: 'code-sample-filters',
  CODE_SAMPLE_FEATURED: 'code-sample-featured',
  IDEA_LIST: 'idea-list',
  IDEA_DETAIL: 'idea-detail',
  IDEA_FILTERS: 'idea-filters',
  IDEA_FEATURED: 'idea-featured',
};

export { queryKeys };
