import React from 'react';
import Logo from '../../images/DiligentLogo.png';
import { EmailIcon } from '@diligentcorp/atlas-react-icons';
import { LoginIcon } from '@devportal/common';
import { Link } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';

function Navbar({ isloggedIn, isLoading, onLogInOutClick }: any) {
  return (
    <nav className="h-25 flex items-center mx-auto container p-2 justify-between">
      <Link to="/">
        <img src={Logo} alt="Diligent Logo" />
      </Link>
      <div className="flex items-center">
        <a href="mailto:galvanizesupport@diligent.com">
        <Button>
          <EmailIcon style={{ fontSize: '24px' }} className="text-text-800"/>
          <p className="font-bold text-text-800">Contact Support</p>
        </Button>
        </a>
        {isLoading ? (
          <Skeleton width={60} height={40} />
        ) : (
          <Button
            onClick={() => {
              if (onLogInOutClick) {
                onLogInOutClick();
              }
            }}
          >
            <LoginIcon style={{ fontSize: '24px' }} />
            <p className="font-bold text-brand-100">{isloggedIn ? 'Logout' : 'Login'}</p>
          </Button>
        )}
      </div>
    </nav>
  );
}

export { Navbar };
