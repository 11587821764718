const atlasThemeOverride = {
  typography: {
    fontFamily: '"Plus Jakarta Sans", sans-serif',
  },
  palette: {
    primary: {
      main: '#E00D00',
    },
  },
};

export { atlasThemeOverride };
