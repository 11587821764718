import { OidcClient } from 'oidc-client-ts';
import { apiUrl, AUTH_PROVIDER } from './endpoints';

const oidcClient = new OidcClient({
  authority: AUTH_PROVIDER,
  client_id: 'developer_hub',
  redirect_uri: `${window.location.origin}/oidc/callback`,
  response_type: 'code',
  resource: apiUrl,
  scope: 'scripts.read',
});

export { oidcClient };
