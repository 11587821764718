import React from 'react';
import { Checkbox } from '@mui/material';

function FilterGroup({ heading, onClear, items, checkMap, onChangeHandler }) {
  return (
    <div>
      <div className="flex justify-between items-center font-semibold">
        <p className="text-black">{heading}</p>
        {items.some((item) => checkMap[item.filter_id]) ? (
          <p
            className="text-xs text-brand-300 cursor-pointer"
            onClick={() => {
              onClear();
            }}
          >
            Clear
          </p>
        ) : null}
      </div>

      <div className="p-3">
        {items.map((item, i) => (
          <div className={`flex items-center ${i !== 0 ? 'mt-3' : ''}`}>
            <Checkbox
              size="small"
              color="default"
              checked={checkMap[item.filter_id] || false}
              onChange={(e) => {
                onChangeHandler(item.filter_id, e.target.checked);
              }}
            />
            <div className="text-sm ml-3">{item.filter_name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export { FilterGroup };
