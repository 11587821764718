import React from 'react';
import { DocumentCurlIcon, ScriptIcon, ForkIcon, GearsIcon } from '@devportal/common';
import RedApiBg from 'images/redapibg.png';
import BlackSdkBg from 'images/blacksdkbg.png';
import { GridCard } from './GridCard';

function DocumentationGrid() {
  return (
    <div className="shadow-basic bg-white">
      <div className="flex flex-wrap">
        <GridCard
          style={{
            backgroundImage: `url(${RedApiBg})`,
          }}
          className="text-white"
          icon={<DocumentCurlIcon />}
          title="Diligent One platform APIs"
          description="Use Diligent's APIs to automate interactions with our Diligent One platform, build reporting, and integrate with other systems in your company"
          links={[
            { name: 'Diligent One platform APIs', href: '/api/highbond', shouldReload: true },
            { name: 'Diligent Entity APIs', href: '/api/entities', shouldReload: true },
            { name: 'Diligent ESG APIs', href: '/api/esg', shouldReload: true },
            { name: 'Diligent Workflow APIs', href: '/api/workflow', shouldReload: true },
          ]}
          linkClass="text-white"
        />
        <GridCard
          className="text-text-300"
          icon={<ScriptIcon />}
          title="Scripting for Robots"
          desClass="text-text-900"
          description="Analytics provides a powerful combination of data access, data analysis, and integrated reporting. You can
        use our ACL scripting language for critical data analysis or our custom Python library for domain-focused
        data analysis and HighBond automation. We also have a library of pre-written analytic scripts to power up
        your process."
          links={[
            {
              name: 'ACL Scripting',
              href: 'https://help.highbond.com/helpdocs/analytics/current-version/en-us/Default.htm#cshid=using-acl-analytics',
              target: '_blank',
            },
            {
              name: 'HCL Scripting',
              href: 'https://help.highbond.com/helpdocs/highbond/en-us/Default.htm#cshid=rob-scripting-in-robots',
              target: '_blank',
            },
            { name: 'Code Samples', href: 'code-samples' },
          ]}
          linkClass="text-brand-150"
        />
      </div>
      <div className="flex flex-wrap-reverse">
        <GridCard
          className="text-text-300"
          icon={<ForkIcon />}
          desClass="text-text-150"
          title="Integration"
          description="Whether your data source is file-based, big data, ERP/CRM, API or ODBC compliant, our Analytics data
          connectors provide access to a wide variety of file types, databases, and cloud data sources."
          links={[
            {
              name: 'Connectors',
              href: 'https://help.highbond.com/helpdocs/analytics/current-version/en-us/Default.htm#cshid=data-sources-acl',
              target: '_blank',
            },
            { name: 'Partners', href: 'https://www.diligent.com/showcase/', target: '_blank' },
          ]}
          linkClass="text-brand-150"
        />
        <GridCard
          style={{
            backgroundImage: `url(${BlackSdkBg})`,
          }}
          className="text-white"
          icon={<GearsIcon />}
          title="SDK"
          description="Our toolkits contain a pre-configured solution of tools aimed at addressing one area of risk or compliance
        using our HighBond platform. Use this documentation to learn how to create toolkits that address your
        business needs."
          links={[
            { name: 'Reference Documentation', href: '/terraform-docs', shouldReload: true },
            { name: 'Best Practices', href: '/docs/best_practices', shouldReload: true },
            { name: 'Changelog', href: '/ChangeLogHistory', shouldReload: true },
          ]}
          linkClass="text-white"
        />
      </div>
    </div>
  );
}

export { DocumentationGrid };
