import React from 'react';
import { Link } from 'react-router-dom';
import { ExpandRightIcon } from '@diligentcorp/atlas-react-icons';

function ArrowLink({
  textProps,
  iconProps,
  wrapperProps,
  name,
  href,
  isUnderline = true,
  isIcon = true,
  ...rest
}: any) {
  return (
    <Link to={href} style={{ textDecoration: 'none' }} {...rest}>
      <div {...wrapperProps} className={`flex group items-center ${wrapperProps?.className || ''}`}>
        <div
          {...textProps}
          className={`${isUnderline ? 'underline underline-offset-4' : ''} ${textProps?.className || ''}`}
        >
          {name}
        </div>

        {isIcon ? (
          <ExpandRightIcon className="transition-transform ml-2 transform group-hover:translate-x-2" {...iconProps} />
        ) : null}
      </div>
    </Link>
  );
}

export { ArrowLink };
