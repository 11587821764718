export const contents = {
  footer: {
    contactUs: 'Contact Us',
    contactNo: '+1 604 669 4225',
    privacy: 'Privacy Policy',
    terms: 'Terms of Use',
    vulnerablity: 'Diligent Vulnerability Disclosure Program',
    reference: 'REFERENCE',
    guides: 'GUIDES',
    company: 'COMPANY',
    helpDocs: 'Help Docs',
    apiTerm: 'API Terms of Use',
    aclGuide: 'ACL Guide',
    hclGuide: 'HCL Guide',
    aboutUs: 'About Us',
    careers: 'Careers',
    partners: 'Partners',
    newsRoom: 'Newsroom',
    copyRight: 'Diligent Corporation',
  },
};
