import React, { useEffect } from 'react';
import { Navbar, HomeBanner, ArrowLink, Footer } from '@devportal/common';
import { IdeasHubExplore, DocumentationGrid, WithNavLayout } from 'components';
import { useAuthStore } from 'store';

function HomePage() {
  const authState: any = useAuthStore((state) => state);
  return (
    <WithNavLayout>
      <HomeBanner />
      <IdeasHubExplore />
      <div>
        <div className="container mx-auto h-98">
          <div className="flex flex-col items-center pt-16">
            <h1 className="text-text-800 font-semibold text-5xl mb-6">Documentation</h1>
            <p className="text-text-500 max-w-160 text-center">
            The following helpful links give quick access documentation, best practices and code samples to 
            get started with APIs, ACL Analytics, Toolkits and Integrations
            </p>
          </div>
        </div>

        <div className="bg-sea-50 flex pb-16">
          <div className="container mx-auto">
            <div style={{ marginTop: '-120px' }}>
              <DocumentationGrid />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </WithNavLayout>
  );
}

export { HomePage };
