import React from 'react';
import banner from '../../images/homeherobanner.png';
import DiligentAclRobot from '../../images/Diligent-ACL-logo.png';
import Fedramp from '../../images/fedramp.png';

function HomeBanner() {
  return (
    <div
      style={{
        backgroundImage: `url(${banner})`,
      }}
      className="bg-cover min-h-120 flex items-center"
    >
      <div className="container mx-auto p-2">
        <div className="flex flex-col justify-between max-w-150 min-h-82">
          <div>
            <div className="text-5xl font-semibold mb-1 text-text-800">Welcome to Diligent</div>
            <div className="text-brand-300 text-7xl font-semibold">Developer Portal</div>

            <p className="text-text-500 mt-5 text-xl">
              Find everything you need to build Modern GRC solutions on the Diligent platform - ACL Analytics Resources,
              Examples, Guides, Documentation and more
            </p>
          </div>
          <div>
            <div className="flex items-center">
              <div className="flex justify-center items-center">
                <img src={DiligentAclRobot} className="h-12"/>
              </div>
              <div className="w-[1px] h-6 bg-dark-10 mx-6"></div>
              <div className="flex justify-center items-center">
                <div className="flex items-center">
                  <img src={Fedramp} className="h-12"/>
                  <div className="max-w-60 ml-3">
                    <h1 className="text-text-800 font-semibold text-xs">FedRAMP <div className="mt-2"> Authorised partner </div></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { HomeBanner };
